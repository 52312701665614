import supabase from "../createClient";
import UserGroup from "../../common/enums";
import { getTodaysDate } from "../../helpers/dateUtils";

class UserSettings {
  static async getUserId() {
    const { data, error } = await supabase.auth.getSession();

    if (error) {
      throw error;
    }

    const { data: user, error: userError } = await supabase
      .from("users")
      .select("id")
      .eq("email", data.session.user.email)
      .single();

    if (userError) {
      throw userError;
    }

    return user.id;
  }

  static async userGroup() {
    const { data, error } = await supabase.auth.getSession();

    if (error) {
      throw error;
    }

    const { data: user, error: userError } = await supabase
      .from("users")
      .select("usergroupid")
      .eq("email", data.session.user.email)
      .single();

    if (userError) {
      throw userError;
    }

    return user.usergroupid;
  }

  static async hascompletedOnboarding() {
    const { data, error } = await supabase.auth.getSession();

    if (error) {
      throw error;
    }

    if (!data.session || !data.session.user || !data.session.user.email) {
      return null;
    }

    const { data: user, error: userError } = await supabase
      .from("users")
      .select("status")
      .eq("email", data.session.user.email)
      .single();

    if (userError) {
      throw userError;
    }

    return user.status !== 0;
  }

  static async getUserName() {
    const { data, error } = await supabase.auth.getSession();

    if (error) {
      throw error;
    }

    if (data.session && data.session.user && data.session.user.email) {
      const { data: user, error: userError } = await supabase
        .from("users")
        .select("name")
        .eq("email", data.session.user.email)
        .single();

      if (userError) {
        throw userError;
      }

      return user.name;
    }
  }

  static async updateUserInfo(
    firstName,
    isMetric,
    gender,
    activityLevel,
    age,
    height,
    weight
  ) {
    const dateStart = getTodaysDate();
    const userGroup = UserGroup.RegisteredUsers;

    try {
      const userId = await this.getUserId();

      const { error: usersError } = await supabase
        .from("users")
        .update({
          name: firstName,
          datestart: dateStart,
          ismetric: isMetric,
          usergroupid: userGroup,
          activity_level: activityLevel,
          gender: gender,
          status: 1,
          age: parseInt(age),
          height: parseInt(height),
          startingweight: parseFloat(weight),
        })
        .eq("id", userId);

      if (usersError) {
        throw new Error(`Error updating users: ${usersError.message}`);
      }

      return { success: true };
    } catch (error) {
      return { error: error.message };
    }
  }
}

export default UserSettings;

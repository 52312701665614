import { createClient } from "@supabase/supabase-js";

const isLocalHost = process.env.NODE_ENV === "development";

let supabase = null;

if (isLocalHost) {
  supabase = createClient(
    process.env.VUE_APP_SUPABASE_URL_LOCAL,
    process.env.VUE_APP_SUPABASE_KEY_LOCAL
  );
} else {
  supabase = createClient(
    process.env.VUE_APP_SUPABASE_URL,
    process.env.VUE_APP_SUPABASE_KEY
  );
}

export default supabase;
